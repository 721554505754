<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_queueLogForm">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        队列类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="QueueType">
                            <el-input v-model="dataSource.QueueType" readonly></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3" class="form-title">
                        队列名称：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="QueueName">
                            <el-input v-model="dataSource.QueueName" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">
                        开始请求时间：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="RequestTime">
                            <el-input v-model="dataSource.RequestTime" readonly></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3" class="form-title">
                        结束请求时间：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="ResponseTime">
                            <el-input v-model="dataSource.ResponseTime" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">
                        处理结果：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="DisplayDealResult">
                            <el-input v-model="dataSource.DisplayDealResult" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">
                        处理描述：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="OperateDesc">
                            <el-input type="textarea" v-model="dataSource.OperateDesc" :rows="10" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">
                        队列内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="requestContent">
                            <el-input type="textarea" :rows="20" readonly v-model="requestContent"></el-input>
                        </el-form-item>
                        <p style="text-align: right;">
                            <!-- <span> {{$t(`报文转换`)}}：</span> -->
                            <el-link type="primary" @click="showSrc">原文</el-link> &nbsp;
                            <el-link type="primary" @click="jonsDeal">格式化</el-link> &nbsp;
                            <el-link type="primary" @click="urldecode">编码转义</el-link>
                        </p>
                    </el-col>
                </el-form-item>

            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            requestContent: null,
           
            isJsonBuef: true,
            isEncodeBuef: true,
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    methods: {
        showSrc() {
            var _this = this;
            _this.requestContent = _this.dataSource.QueueContent;
           
            _this.isJsonBuef = true;
            _this.isEncodeBuef = true;
        },
        jonsDeal() {
            var _this = this;
            if (_this.isJsonBuef) {
                _this.isJsonBuef = false;
                try {
                    var jsonObj = JSON.parse(_this.requestContent || '');
                    _this.requestContent = JSON.stringify(jsonObj, null, 4);
                } catch (e) {
                    _this.requestContent = _this.requestContent;
                }
                try {
                    jsonObj = JSON.parse(_this.responseContent || '');
                    _this.responseContent = JSON.stringify(jsonObj, null, 4);

                } catch (e) {
                    _this.responseContent = _this.responseContent;
                }

            }
            else {
                // _this.isJsonBuef = true;
                // _this.requestContent = _this.detailSource.RequestContent;
                // _this.responseContent = _this.detailSource.ResponseContent;
            }
        },
        urldecode() {
            var _this = this;
            if (_this.isEncodeBuef) {
                _this.isEncodeBuef = false;
                _this.requestContent = decodeURIComponent(_this.requestContent);
                _this.responseContent = decodeURIComponent(_this.responseContent);
            }

        }
    }
}
</script>
